import React from "react";
import cmsUtils from "../../utils/cmsUtils";
import utils from "../../../utils";
import {renderHtml} from "../../../site/_cmsItems/html/view/renderHtml";

export function MultiColumnText(props) {
  const item = props.item;
  const contents = {
    content0 : cmsUtils.payload(item, "Content") || "Please add text",
    content1 : cmsUtils.payload(item, "Content2") || "Please add text",
    content2 : cmsUtils.payload(item, "Content3") || "Please add text",
    content3 : cmsUtils.payload(item, "Content4") || "Please add text",
    content4 : cmsUtils.payload(item, "Content5") || "Please add text",
    content5 : cmsUtils.payload(item, "Content6") || "Please add text"
  }
  
  const cmsOption = props.cmsOption || {};
  const columnWidths = cmsUtils.payload(item, "ColumnWidths").split(",");

  const mobileColumnWidths = cmsUtils
    .payload(item, "MobileColumnWidths")
    .split(",");
  const countItem = { countD: 0, countM: 0, col: "" };
  //const itemNumber = columnWidths.length;

  const bgColor = cmsUtils.payload(item, "BgColor");
  //const textColorTheme = cmsUtils.payload(item, 'TextColorTheme');
  const imageUrl = cmsUtils.payload(item, "ImageUrl");
  const backgroundTile = cmsUtils.payload(item, "BackgroundTile");
  const bgTheme = cmsUtils.payload(item, "TextColorTheme") || "white";
  const panelWidth = cmsUtils.payload(item, "PanelWidth");

  let bgUrl = null;
  if (imageUrl) bgUrl = utils.site.resourcePath(imageUrl);
  else if (backgroundTile)
    bgUrl = utils.site.resourcePath(
      `/dist/transparent-textures/textures/${backgroundTile}`
    );

  const cssClass =
    panelWidth === "full"
      ? utils.classNames(
          "cms_item",
          "contentPanel",
          "multiColumnPanel",
          "multiColumnText__new",
          "multiColumnPanelFull",
          "contentPanel--" + bgTheme,
          item.cssClass || item.anchorName || ""
        )
      : utils.classNames(
          "cms_item",
          "contentPanel",
          "multiColumnPanel",
          "multiColumnText__new",
          "contentPanel--" + bgTheme,
          item.cssClass || item.anchorName || ""
        );

  function setupClass() {
    countItem.col = "";
    if (columnWidths.length === countItem.countD) {
      countItem.countD = 0;
    }

    if (mobileColumnWidths.length === countItem.countM) {
      countItem.countM = 0;
    }

    countItem.col =
      "col-sm" +
      (columnWidths[countItem.countD] === "13"
        ? ""
        : "-" + columnWidths[countItem.countD]) +
      " col-" +
      mobileColumnWidths[countItem.countM];
    countItem.countD++;
    countItem.countM++;
  }

  const subItemsElts = columnWidths.map((itemS, index) => {
    setupClass();
    return (
      <div className={countItem.col} key={index}>
        <GetItem cmsOption = {cmsOption}  itemId = {item.itemId}  itemItemId = {index === 0 ? item.itemId : item.itemId + "_" + (index)} content = {contents['content' + index]} index = {index}/>
        {/* <Item item={subItem} cmsOption={cmsOption}></Item> */}
      </div>
    );
  });
  return (
    <section
      className={cssClass}
      style={{
        backgroundColor: `${bgColor}`,
        backgroundImage: bgUrl && utils.css.bgUrlStyle(bgUrl),
      }}
      data-cms-item-id={cmsOption.isCmsEdit && item.itemId}
      id={item.anchorName}
    >
      <div className='container'>
        <div className='row'>{subItemsElts}</div>
      </div>
    </section>
  );
}


function GetItem({cmsOption, itemId, itemItemId, content, index}){
  return(
    <div
      data-cms-html='true'
      data-cms-item-id = {itemItemId}
      data-cms-item-id2={itemId}
      className={`cms_item htmlPanel html new__multicolumn__html item__${index}`}>
      {renderHtml(content, cmsOption)}
    </div>
  )
}