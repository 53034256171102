import React, { useState } from "react";
import cmsUtils from "../../utils/cmsUtils";
import utils from "../../../utils";

export function FAQPanel(props) {
  const item = props.item;
  const subItems = item.items || [];
  const cmsOption = props.cmsOption || {};
  const primaryColor = cmsUtils.payload(item, "BgColor");
  const imageUrl = cmsUtils.payload(item, "ImageUrl");
  const backgroundTile = cmsUtils.payload(item, "BackgroundTile");

  const [selected, setSelected] = useState(null);
  const toggle = (i) => {
    if (selected === i) {
      return setSelected(null);
    }
    setSelected(i);
  };

  let bgUrl = null;
  if (imageUrl) bgUrl = utils.site.resourcePath(imageUrl);
  else if (backgroundTile)
    bgUrl = utils.site.resourcePath(
      `/dist/transparent-textures/textures/${backgroundTile}`
    );
  const cssClass = utils.classNames(
    "cms_item",
    "accordionPanel",
    "contentPanel--",
    item.cssClass || item.anchorName || ""
  );
  const borderStyle = "1px solid " + primaryColor;
  console.log(
    "%c [ subItems ]-36",
    "font-size:13px; background:#C0CF67; color:#495300;",
    subItems
  );
  // text = subItems[0].payloads[1].value.replaceAll("/r/n","<hr />");

  const subItemsElts = subItems.map((subItem, index) => (
    <div className='wrapper' key={index}>
      <div className={selected === index ? "accordion show" : "accordion"}>
        <div
          className='item'
          style={{
            borderTop: borderStyle,
            borderBottom: index === subItems.length - 1 ? borderStyle : "",
          }}
        >
          <div
            className={selected === index ? "title show" : "title"}
            onClick={() => toggle(index)}
          >
            <h4>{subItem.payloads[0].value}</h4>
            <span className='open_button'>
              <svg
                width='25px'
                height='25px'
                viewBox='0 0 48 48'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path d='M0 0h48v48H0z' fill='none' />
                <g id='Shopicon'>
                  <polygon
                    fill={primaryColor}
                    points='24,29.172 9.414,14.586 6.586,17.414 24,34.828 41.414,17.414 38.586,14.586  '
                  />
                </g>
              </svg>
            </span>
          </div>
          <div className={selected === index ? "content show" : "content"}>
            <div
              className='content_text'
              dangerouslySetInnerHTML={{
                __html: subItem.payloads[1].value.replaceAll(
                  "\r\n",
                  "<div><div/>"
                ),
              }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  ));

  return (
    <>
      <section
        className={cssClass}
        style={{
          backgroundImage: bgUrl && utils.css.bgUrlStyle(bgUrl),
        }}
        data-cms-item-id={cmsOption.isCmsEdit && item.itemId}
        id={item.anchorName}
      >
        <div className='container'>
          <div className='accordion_container'>{subItemsElts}</div>
        </div>
      </section>
    </>
  );
}
