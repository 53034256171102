/** @jsx jsx */
import { jsx } from '@emotion/core';
import style from './style';
import utils from '../../../utils';
import ImageGallery from 'react-image-gallery';
import FsLightbox from 'fslightbox-react';
import { useState } from 'react';

export function PhotoSlide(props) {
  const item = props.item;
  const subItems = item.items || [];
  const cmsOption = props.cmsOption || {};
  const cssClass = utils.classNames(
    'cms_item',
    'photoSlide',
    item.cssClass || item.anchorName || ''
  );
  const images = [];
  subItems.map((subItem, index) => {
    subItem.payloads[0].value.split('|').map((value, index) => {
      images.push({
        original: value,
        thumbnail: value,
      });
      return null;
    });
    return null;
  });
  const [toggler, setToggler] = useState(false);
  const [imageUrl, setImageUrl] = useState('');
  const _handleClick = (event) => {
    setImageUrl(event.target.src);
    console.log(imageUrl);
    setToggler(!toggler);
  };

  return (
    <div
      css={style.photoSlide}
      className={cssClass}
      data-cms-item-id={cmsOption.isCmsEdit && item.itemId}
      id={item.anchorName}
    >
      <FsLightbox
        toggler={toggler}
        sources={[imageUrl]}
        disableBackgroundClose={true}
      />
      <ImageGallery
        items={images}
        slideInterval={2000}
        showFullscreenButton={false}
        onClick={_handleClick}
      />
    </div>
  );
}
