import React from 'react';
import cmsUtils from '../../utils/cmsUtils';
import utils from '../../../utils';
import { SiteLink } from '../../../components';

import Slider from "react-slick";

export function PageTiles(props) {
  const item = props.item;
  const cmsOption = props.cmsOption || {};

  //console.log('item', item);
  const useSlider = cmsUtils.payload(item, 'UseSlider') === 'slider';
  const pages = getPages(cmsUtils.payload(item, 'PageObjects'))
  //console.log(pages);
  const pageTileElts = pages.map((page, index) =>
    <Tile page={page} key={index} disableLink={cmsOption.isCmsEdit}></Tile>
  );

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplaySpeed: 3000,
    fade: false,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ]
  };

  const cssClass = utils.classNames('cms_item', 'pageTiles tiles', useSlider && 'useSlider', item.cssClass || item.anchorName || '');

  if (useSlider){
    return (
      <div className={cssClass} data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName}>
        <Slider {...settings} >
          {pageTileElts}
        </Slider>
      </div>
    );
  } else {
    return (
      <div className={cssClass} data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName}>
        {pageTileElts}
      </div>
    );   
  }
}

function getPages(pagesSerialized) {
  if (pagesSerialized) {
    return JSON.parse(pagesSerialized);
  }
  return [];
}

function Tile(props) {
  const page = props.page;
  const imageUrl = utils.site.resourcePath(page.imageUrl);
  const disableLink = props.disableLink;
  const colorTheme = (page.colorTheme && page.colorTheme === 'default') ? 'red' : page.colorTheme;
  //const secondImageUrl = utils.site.resourcePath(page.bottomImageUrl);
  const secondImageUrl = imageUrl;
  
  
  return (
    <div className={`44444 pageTile tile ${colorTheme}`}>
      <SiteLink className={`pageTile__content tile__content ${colorTheme}`} to={disableLink ? '' : page.pageUrl}>
        <div className="tile__bg" style={{ backgroundImage: utils.css.bgUrlStyle((secondImageUrl && secondImageUrl !== '') ? secondImageUrl : '')}}>
          <div className='tile__line'>
            <div style={{position:"relative"}}>
              <svg viewBox="0 0 500 50" preserveAspectRatio="none">
                <path d="M0,50 L420,0 L500,0 L500,50 Z" fill="white" />
              </svg>
              <div className='tile__line__plus'>
                  <img src={`/assets/icons/${colorTheme + '.png'}`} alt='plus'/>
              </div>
            </div>
          </div>
        </div>
        <div className={`pageTile_details tile__details`}>
          <div className="pageTile__title">
            {page.pageTitle}
          </div>
          <hr className={`pageTile__line__style ${colorTheme}`} />
          <div className="pageTile_desc">
            {page.description}
          </div>

          <div className='pageTile_read_more'>
            READ MORE
          </div>

        </div>
      </SiteLink>
    </div>
  )
}