// import React from "react";
// import { au } from "components";

// export function useParallax(panelRef, _settings) {
//   const [settings] = React.useState(getSettings(_settings));
//   const handleScroll = () => {
//     //console.log('useParallax - scrolling')
//     onScroll(settings, panelRef.current);
//   };

//   const reset = () => {
//     //console.log('useParallax - reset');
//     setTimeout(() => {
//       onResize(settings, panelRef.current);
//       handleScroll();
//     }, 300);
//   };

//   React.useEffect(() => {
//     reset();
//     if (typeof window !== `undefined`) {
//       window.addEventListener("scroll", handleScroll);
//       window.addEventListener("resize", reset);
//     }
//     return () => {
//       if (typeof window !== `undefined`) {
//         window.removeEventListener("scroll", handleScroll);
//         window.removeEventListener("resize", reset);
//       }
//     };
//     // eslint-disable-next-line
//   }, []);
//   return { reset };
// }

// function getSettings(settings) {
//   const defaultSettings = {
//     cutoffPadding: 10, //px
//     paddingHeightRatio: 1,
//     stretchToFit: true,
//     actionRange: [0.1, 0.9],
//     imageRatio: 1080 / 1920,
//     panelHeight: "auto",
//     cutTop: 0,
//     cutBottom: 0,
//   };
//   return Object.assign({}, defaultSettings, settings);
// }

// export function onResize(settings, el) {
//   const screenSize = au.getScreenSize();
//   const autoPanelHeight =
//     !settings.panelHeight || settings.panelHeight === "auto";
//   const panelHeight = autoPanelHeight
//     ? el.offsetHeight
//     : parseFloat(settings.panelHeight);
//   //console.log(autoPanelHeight, panelHeight)

//   let bgHeight = 0,
//     bgWidth = 0;
//   bgHeight =
//     panelHeight * (1 + settings.paddingHeightRatio) +
//     2 * settings.cutoffPadding;
//   bgWidth =
//     (bgHeight * (1 + settings.cutTop + settings.cutBottom)) /
//     settings.imageRatio;
//   //console.log(panelHeight, settings.paddingHeightRatio, bgHeight, bgWidth)
//   if (settings.stretchToFit && bgWidth < screenSize.width) {
//     // magnifying the background image to fix the full width of the browser
//     bgHeight = screenSize.width * settings.imageRatio;
//     bgWidth =
//       (bgHeight * (1 + settings.cutTop + settings.cutBottom)) /
//       settings.imageRatio;
//     //console.log('magnified', bgHeight, bgWidth, screenSize)
//   }

//   const cutTopPx =
//     bgHeight * (1 + settings.cutTop + settings.cutBottom) * settings.cutTop;
//   const cutBottomPx =
//     bgHeight * (1 + settings.cutTop + settings.cutBottom) * settings.cutBottom;
//   let fromTop = -settings.cutoffPadding;
//   let toTop = -(bgHeight - panelHeight - settings.cutoffPadding);
//   fromTop += fromTop - toTop > cutTopPx ? cutTopPx : 0;
//   toTop += fromTop - toTop > cutBottomPx ? cutBottomPx : 0;
//   //console.log('shift', cutTopPx, cutBottomPx, bgHeight, fromTop, toTop)

//   el.setAttribute("data-fromTop", fromTop);
//   el.setAttribute("data-toTop", toTop);

//   if (!autoPanelHeight) el.style.height = settings.panelHeight + "px";

//   el.style.backgroundSize = bgWidth + "px " + bgHeight + "px";
// }

// export function onScroll(settings, el) {
//   const pr = au.getScrollProgress(el).progress;
//   //console.log(pr, panel.calculated);

//   const min = parseFloat(el.getAttribute("data-fromTop"));
//   const max = parseFloat(el.getAttribute("data-toTop"));
//   const top = au.prVal(min, max, pr, settings.actionRange /*[0, 1]*/);
//   //console.log(min, max, top);
//   el.style.backgroundPositionY = top + "px";
// }

import React from "react";
import { au } from "components";

export function useParallax(panelRef, _settings) {
  const [settings] = React.useState(getSettings(_settings));
  const handleScroll = () => {
    //console.log('useParallax - scrolling')
    onScroll(settings, panelRef.current);
  };

  const reset = () => {
    //console.log('useParallax - reset');
    setTimeout(() => {
      onResize(settings, panelRef.current);
      handleScroll();
    }, 300);
  };

  React.useEffect(() => {
    reset();
    if (typeof window !== `undefined`) {
      window.addEventListener("scroll", handleScroll);
      window.addEventListener("resize", reset);
    }
    return () => {
      if (typeof window !== `undefined`) {
        window.removeEventListener("scroll", handleScroll);
        window.removeEventListener("resize", reset);
      }
    };
    // eslint-disable-next-line
  }, []);
  return { reset };
}

function getSettings(settings) {
  const defaultSettings = {
    cutoffPadding: 10, //px
    paddingHeightRatio: 1,
    stretchToFit: true,
    actionRange: [0.1, 0.9],
    imageRatio: 1080 / 1920,
    panelHeight: "auto",
    cutTop: 0,
    cutBottom: 0,
  };
  return Object.assign({}, defaultSettings, settings);
}

export function onResize(settings, el) {
  const screenSize = au.getScreenSize();
  const autoPanelHeight =
    !settings.panelHeight || settings.panelHeight === "auto";

  const panelHeight =
    autoPanelHeight && el ? el.offsetHeight : parseFloat(settings.panelHeight);
  //console.log(autoPanelHeight, panelHeight)

  let bgHeight = 0,
    bgWidth = 0;
  bgHeight =
    panelHeight * (1 + settings.paddingHeightRatio) +
    2 * settings.cutoffPadding;
  bgWidth =
    (bgHeight * (1 + settings.cutTop + settings.cutBottom)) /
    settings.imageRatio;
  //console.log(panelHeight, settings.paddingHeightRatio, bgHeight, bgWidth)
  if (settings.stretchToFit && bgWidth < screenSize.width) {
    // magnifying the background image to fix the full width of the browser
    bgHeight = screenSize.width * settings.imageRatio;
    bgWidth =
      (bgHeight * (1 + settings.cutTop + settings.cutBottom)) /
      settings.imageRatio;
    //console.log('magnified', bgHeight, bgWidth, screenSize)
  }

  const cutTopPx =
    bgHeight * (1 + settings.cutTop + settings.cutBottom) * settings.cutTop;
  const cutBottomPx =
    bgHeight * (1 + settings.cutTop + settings.cutBottom) * settings.cutBottom;
  let fromTop = -settings.cutoffPadding;
  let toTop = -(bgHeight - panelHeight - settings.cutoffPadding);
  fromTop += fromTop - toTop > cutTopPx ? cutTopPx : 0;
  toTop += fromTop - toTop > cutBottomPx ? cutBottomPx : 0;
  //console.log('shift', cutTopPx, cutBottomPx, bgHeight, fromTop, toTop)

  el && el.setAttribute("data-fromTop", fromTop);
  el && el.setAttribute("data-toTop", toTop);

  if (!autoPanelHeight) el.style.height = settings.panelHeight + "px";

  el && (el.style.backgroundSize = bgWidth + "px " + bgHeight + "px");
}

export function onScroll(settings, el) {
  const pr = au.getScrollProgress(el).progress;
  //console.log(pr, panel.calculated);

  const min = parseFloat(el && el.getAttribute("data-fromTop"));
  const max = parseFloat(el && el.getAttribute("data-toTop"));
  const top = au.prVal(min, max, pr, settings.actionRange /*[0, 1]*/);
  //console.log(min, max, top);
  el && (el.style.backgroundPositionY = top + "px");
}
