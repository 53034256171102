import React, { useLayoutEffect, useRef } from "react";
import ReactDOM from "react-dom";
import cmsUtils from "../../utils/cmsUtils";
import utils from "../../../utils";

import { useRouter } from "../../../components";

import { handleElementLink } from "../../../components";
import env from "../../../env";

//import { Document, Page } from 'react-pdf'
//import { Document, Page } from 'react-pdf/dist/entry.webpack';

export function Html(props) {
  const ver = env.ver;
  const item = props.item;
  //const subItems = item.items || [];
  const cmsOption = props.cmsOption || {};
  const { history } = useRouter();
  //const [ pdfViewPath, setPdfViewPath ] = React.useState(null)
  //console.log('item', item, cmsOption);

  const year = new Date().getFullYear();
  let content = cmsOption.isCmsEdit
    ? cmsUtils.payload(item, "Content")
    : cmsUtils
        .payload(item, "Content")
        .replace(/__year__/g, year)
        .replace(
          "__version__",
          "<a href = '/app' class = 'app_version'> PWA " + ver + "</a>"
        );

  content = content
    .replace(
      /class="aos_effect_up"/gi,
      `class="aos_effect_up" data-aos="fade-up"`
    )
    .replace(
      /class="aos_effect_down"/gi,
      `class="aos_effect_down" data-aos="fade-down"`
    )
    .replace(
      /class="aos_effect_left"/gi,
      `class="aos_effect_left" data-aos="fade-left"`
    )
    .replace(
      /class="aos_effect_right"/gi,
      `class="aos_effect_right" data-aos="fade-right"`
    );

  const cssClass = utils.classNames(
    "cms_item",
    "htmlPanel",
    cmsOption.isCmsEdit && "html",
    item.cssClass || item.anchorName || ""
  );

  //let itemElt = (<div dangerouslySetInnerHTML={{ __html: content }}></div>);

  function handleClick(e) {
    let target = "";
    if (e.target.closest("a")) {
      target = e.target.closest("a").getAttribute("target");
    }
    const processed =
      target === "_blank"
        ? handleElementLink(e.target, null)
        : handleElementLink(e.target);
    //const link = utils.url.getLinkFromElement(e.target);
    //console.log('clicked', e, e.target, processed);

    if (processed.handled) {
      e.preventDefault();
      e.stopPropagation();
    } else if (processed.linkInfo && processed.linkInfo.type === "internal") {
      e.preventDefault();
      e.stopPropagation();
      history.push(processed.linkInfo.url);
    } else {
      //e.preventDefault();
      //e.stopPropagation();
      // setPdfViewPath(utils.site.resourcePath(link))

      if (e.target.closest("a")) {
        let href = e.target.closest("a").getAttribute("href") + "";
        if (href.indexOf("#") !== -1) {
          let _href = href.split("#");
          console.log("_href", _href);
          let el = document.querySelector(
            'section[data-cms-item-anchor="' + _href[1] + '"]'
          );
          let anchorStatus = el.getAttribute("data-anchor-status");
          if (anchorStatus === "collapse") {
            el.setAttribute("data-anchor-status", "expand");
            el.classList.add("expand");
          } else {
            el.setAttribute("data-anchor-status", "collapse");
            el.classList.remove("expand");
          }
          e.preventDefault();
          e.stopPropagation();
        }
      }
    }
  }
  //console.log(pdfViewPath)
  const pdfView =
    // pdfViewPath ?
    // <Document file={pdfViewPath}><Page pageIndex={1}/></Document> :
    null;

  const htmlBlock = useRef(null);
  // function iframeSizer() {
  //   //console.log('iframesizer')
  //   let iframesContainer = htmlBlock.current.querySelectorAll('iframe.youtube, .ytvideo iframe');
  //   //console.log('iframesizer', iframesContainer)
  //   if (iframesContainer && iframesContainer.length) {
  //     iframesContainer.forEach(element => {
  //       let height = (element.parentElement.offsetWidth * 9) / 16
  //       //console.log(height, element.parentElement.offsetWidth)
  //       element.style.height = height + 'px';
  //     });
  //   }
  // }

  useLayoutEffect(() => {
    //console.log(cmsOption.isCmsEdit)
    if (!cmsOption.isCmsEdit || cmsOption.cmsViewMode === "viewonly") {
      replaceEmbedCodes(htmlBlock.current, cmsOption);
    }

    const buttons = htmlBlock.current.querySelectorAll(".button");
    for (let i = 0; buttons && i < buttons.length; i++) {
      restructureButton(buttons[i]);
    }

    // iframeSizer();
    // window.addEventListener('resize', iframeSizer)
    // return (): void => {
    //   window.removeEventListener('resize', iframeSizer)
    // }
  }, [cmsOption]);

  return (
    <>
      <div
        ref={htmlBlock}
        className={cssClass}
        data-cms-html='true'
        data-cms-item-id={cmsOption.isCmsEdit && item.itemId}
        id={item.anchorName}
        dangerouslySetInnerHTML={{ __html: content }}
        onClick={(e) => handleClick(e)}
      ></div>
      {pdfView}
    </>
  );
}

function restructureButton(el) {
  if (!el.querySelector("span")) {
    const span = document.createElement("span");
    span.innerHTML = el.innerHTML;
    el.innerHTML = "";
    el.appendChild(span);
  }
}

function replaceEmbedCodes(block, cmsOption) {
  const embedDataList = cmsOption?.pageData?.embedDataList;
  const embedCodes = block.querySelectorAll("[data-embed-jaba]");
  embedCodes.forEach((embed) => {
    const id = embed.getAttribute("data-embed-jaba");
    const found = utils.array.find(embedDataList, (x) => x.id === id);
    if (found) {
      //console.log(found)
      ReactDOM.render(
        <div
          className={`codeType_${found.codeType}`}
          dangerouslySetInnerHTML={{ __html: found.code }}
        ></div>,
        embed
      );
    }
  });
  //console.log(embedCodes, embedDataList)
}
